import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faHourglassEnd, faBriefcase  } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from "react-animate-on-scroll";
import './VerticalTimeline.css';

export default function Timeline() {
  
  return (
    
<VerticalTimeline>
  
  <section id='timeline'>
  <div className="Container">
  <div className="SectionTitle">Timeline</div>

<VerticalTimelineElement 
  className="vertical-timeline-element--work "
  contentStyle={{ background: '#14143a ', color: '#fff' }}
  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
  date="August 2023 - Present"
  dateClassName="custom-date-color"
  iconStyle={{ background: 'red', color: '#fff' }}
  icon={<FontAwesomeIcon icon={faGraduationCap} />}
 
>
  <h3 className="vertical-timeline-element-title">MEng Computer Science & Applications</h3>
  <h4 className="vertical-timeline-element-subtitle">Virginia Tech</h4>
  <h4 className="vertical-timeline-element-subtitle">Falls Church, VA</h4>
  <p>
  Relevant Courseweork: <br></br>
  Software Engineering, Data Structures and Algorithms, Fundamentals of Info Security, 
  Operating Systems, Web Application Development, Ethics & Professionalism 
  </p>
</VerticalTimelineElement>

<VerticalTimelineElement 
  className="vertical-timeline-element--work "
  contentStyle={{ background: '#14143a ', color: '#fff' }}
  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
  date="July 2021 - June 2023"
  dateClassName="custom-date-color"
  iconStyle={{ background: 'red', color: '#fff' }}
  icon={<FontAwesomeIcon icon={faBriefcase} />}
 
>
  <h3 className="vertical-timeline-element-title">Senior Member Technical</h3>
  <h4 className="vertical-timeline-element-subtitle">ADP</h4>
  <h4 className="vertical-timeline-element-subtitle">Hyderabad, India</h4>
  <p>
  • Built RESTful APIs that served data to the Javascript front end based on dynamic user inputs that handled over 1 million clients
  <br />
  • Reduced the manual efforts of the QA team by 90% by automating the performance dashboard scenarios
  </p>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
  date="August 2017 - May 2021"
  dateClassName="custom-date-color"
  contentStyle={{ background: '#14143a', color: '#fff' }}
  iconStyle={{ background: 'red', color: '#fff' }}
  icon={<FontAwesomeIcon icon={faGraduationCap} />}
 
>
  <h3 className="vertical-timeline-element-title">B.Tech Electrical & Electronics Engineering</h3>
  <h4 className="vertical-timeline-element-subtitle">NIT Warangal</h4>
  <h4 className="vertical-timeline-element-subtitle">Telangana, India</h4>
  <p>
  Relevant Courseweork: <br></br>
  Problem Solving and Computer Programming, Data Structures, Probability & Statistics
  </p>
</VerticalTimelineElement>
<VerticalTimelineElement 
  className="vertical-timeline-element--work "
  iconStyle={{ background: 'red', color: '#fff' }}
  icon={<FontAwesomeIcon icon={faHourglassEnd} />}
 
></VerticalTimelineElement>
  </div>
  </section>
</VerticalTimeline>
  )
}
