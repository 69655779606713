import React from 'react';
import './CategoryHobbyListItem.css';
import CategoryHobbyListItem from './HobbiesListItem.tsx';
import { hobbyList, HobbyItem } from '../../types.tsx';

function CategoryHobbyList() {
  return (
    <>
      <div id="hobbies">
        <div className="Container">
        <div className="SectionTitle">Hobbies</div>
        <div className ="hobbies-page">
        <ul className="hobby-lists">
        {hobbyList.map((hobby: HobbyItem) => (
          <CategoryHobbyListItem
            key={hobby.hobbyId} // Adding key prop to fix the missing key warning
            hobbyId={hobby.hobbyId}
            title={hobby.title}
            link={hobby.link}
            description={hobby.description}
          />
        ))}
      </ul>
      </div>
        </div>
      </div>
    </>
  );
}

export default CategoryHobbyList;
