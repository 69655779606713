// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/Libromart.png",
    title: "LibroMart",
    description:
      "Revolutionize your reading experience with my dynamic book store web application, offering an extensive catalog, seamless browsing, and secure transactions, all at your fingertips.",
    tech_stack: ["React Native", "HTML", "CSS", "Figma", "NodeJs"],
    github_url: "https://github.com/Sanjeev2601",
    demo_url: "/",
  },
  {
    img: "/Pet.jpg",
    title: "PawsTime",
    description:
      "pawsTime aims to simplify and enhance pet care management through an integrated scheduling platform that adapts to users' changing needs because Because every pet deserves the VIP treatment!",
    tech_stack: ["React", "Firebase"],
    github_url: "https://github.com/Sanjeev2601",
    demo_url: "/",
  },
  {
    img: "/Casino.png",
    title: "Player's Den",
    description:
      "A website to register and play online casino games.",
    tech_stack: ["Angular", "SpringBoot", "HTML", "CSS", "MySQL"],
    github_url: "https://github.com/Sanjeev2601",
    demo_url: "https://www.youtube.com/watch?v=JxJXHuS87m0",
  },
  {
    img: "/mobile.jpg",
    title: "Mango",
    description:
      "A mobile outlook using C++",
    tech_stack: ["C++"],
    github_url: "https://github.com/Sanjeev2601",
    demo_url: "/",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg",
    name: "Docker",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
    name: "C++",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/spring/spring-original.svg",
    name: "Spring Boot",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://angular.io/assets/images/logos/angular/angular.svg",
    name: "Angular",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
