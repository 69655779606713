export interface HobbyItem {
  hobbyId: number;
  title: string;
  link: string;
  description: string;
}

export const hobbyList: HobbyItem[] = [
  {
    hobbyId: 2001,
    title: 'Leetcode',
    link: 'https://leetcode.com/LebroGems/',
    description: 'Exploring algorithms, writing efficient code, and cherishing the learning process.',
  },
  {
    hobbyId: 2002,
    title: 'Basketball',
    link: '/',
    description: 'On the court, I feel alive, with every movement being a testament to my dedication and love for the game.',
  },
  {
    hobbyId: 2003,
    title: 'Community',
    link: 'https://github.com/Sanjeev2601/Leetcode_Streak',
    description: 'I am working on contributing my solved DSA problems to gitHub community.',
  },
  {
    hobbyId: 2004,
    title: 'Cycling',
    link: '/',
    description: 'With every turn of the pedals, I discover new adventures and a profound sense of freedom, escaping reality.',
  },
];
