import React from 'react';
import { HobbyItem } from '../../types'; // Ensure this path is correct
import './CategoryHobbyListItem.css';

const hobbyImageFileName = (hobby: HobbyItem) => {
  let name = hobby.title;
  return `${name}.png`;
};

function CategoryHobbyListItem(props: HobbyItem) {
  return (
    <ul id="hobby-boxes">
      <li className="hobby-box">
        <div className="hobby-image">
          <img
            src={require('../../Pics/' + hobbyImageFileName(props))}
            alt={props.title}
          />
        </div>
        <div className="hobby-title">{props.title}</div>
        <div className="hobby-description">{props.description}</div>
        {props.link !== '/' && (
          <a href={props.link} target="_blank" rel="noopener noreferrer" className="Learn-More-Button">
            Learn More
          </a>
        )}
      </li>
    </ul>
  );
}

export default CategoryHobbyListItem;
